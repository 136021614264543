<template>
  <div class="white rounded px-4 pb-sm-5 py-sm-16">
    <v-card class="d-flex flex-column justify-center pb-8 pt-5 pt-md-8 pb-sm-16 mx-auto my-sm-16" elevation="0" max-width="240">
      <v-icon
        class="mx-auto"
        size="24"
        color="info"
      >
        $information
      </v-icon>
      <div class="text-uppercase text-center text-subtitle-2 font-weight-bold black--text mt-3 px-3">
        {{ $t('upsale.loader_failure') }}
      </div>
      <c-btn
        color="primary"
        height="46"
        class="mt-6 text-uppercase font-weight-bold text-subtitle-1"
        :label="$t('upsale.look_at_offers')"
        @click="$router.push({ name: routeNames.MARKETPLACE })"
      />
    </v-card>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'UpsaleOffersEmpty',
    components: { CBtn },
    data() {
      return {
        routeNames
      }
    }
  }
</script>
