<template>
  <v-expand-transition mode="out-in">
    <v-card class="pa-4 rounded d-flex flex-column width-289 box-shadow">
      <section class="border-primary rounded px-5 py-3 mb-5 text-center">
        <div class="text-h6 mb-4">
          {{ campaign.adFormat.name }}
        </div>
        <div class="height-200">
          <div class="primary--text height-70 mr-1" v-html="adFormatImage" />
          <div>{{ currentFormatDescription }}</div>
        </div>
      </section>
      <section class="mb-4 flex-grow-1">
        <div
          v-for="(listItem, index) of campaignData"
          :key="index"
          :class="['d-flex justify-space-between mb-1 text-body-2', { 'separator-item': listItem.separator }]"
        >
          <span class="secondary-darken--text">{{ listItem.title }}:</span>
          <v-tooltip bottom :disabled="!campaignTargetLabels[listItem.name]">
            <template #activator="{ on }">
              <div v-on="on">
                <span class="font-weight-medium">{{ listItem.value?.items }}</span>
                <span class="font-weight-medium info--text">{{ listItem.value?.count }}</span>
              </div>
            </template>
            <span>{{ campaignTargetLabels[listItem.name] }}</span>
          </v-tooltip>
        </div>
      </section>
      <section class="mb-4 separator-item">
        <div class="d-flex justify-space-between text-body-2">
          <span class="secondary-darken--text">
            {{ $t('upsale.pricing_model') }}
          </span>
          <span class="font-weight-bold">
            {{ campaign.pricingModel }}
          </span>
        </div>
        <div class="d-flex justify-space-between text-body-2">
          <span class="secondary-darken--text">
            {{ $t('upsale.bid') }}
          </span>
          <span class="font-weight-bold ">
            {{ campaign.price }}
          </span>
        </div>
      </section>
      <c-btn
        class="primary text-uppercase create-btn flex-grow-0 text-body-1 font-weight-medium"
        block
        depressed
        :label="$t('upsale.create')"
        @click="onCreateBtnClick()"
      />
    </v-card>
  </v-expand-transition>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import gtmPush from '@/services/utils/gtm-push.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'UpsaleOfferCard',
    components: { CBtn },
    props: {
      campaign: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        adFormatImage: '',
        campaignTargetLabels: {}
      }
    },
    computed: {
      ...mapGetters('settings', ['getAdFormatsByAdNetwork']),
      currentFormatDescription() {
        return this.getAdFormatsByAdNetwork.find((format) => format.id === this.campaign.adFormat.id)?.description || ''
      },
      campaignData() {
        return [
          {
            name: 'traffic_quality',
            title: this.$t('upsale.traffic_quality'),
            value: { items: this.campaign.targets?.trafficQualityType }
          },
          {
            name: 'category_group',
            title: 'Category group',
            value: this.stringJoiner(
              'category_group',
              this.campaign.targets.categoryGroupTarget.map((category) => category.name)
            )
          },
          {
            name: 'categories',
            title: this.$t('upsale.categories'),
            value: this.stringJoiner(
              'categories',
              this.campaign.targets.categoryTarget.map((category) => category.name)
            )
          },
          {
            name: 'sources',
            title: this.campaign.targets.siteTarget.premium
              ? this.$t('upsale.sources')
              : this.$t('upsale.black_list'),
            value: !this.campaign.targets.siteTarget.accept && this.campaign.targets.siteTarget.premium
              ? { items: this.$t('upsale.all_premium') }
              : this.stringJoiner('sources', this.campaign.targets?.siteTarget.sites.map((site) => site.domain || site.id))
          },
          {
            name: 'countries',
            title: this.$t('upsale.countries'),
            value: this.stringJoiner(
              'countries',
              this.campaign.targets?.countryTarget.map((country) => country.name)
            )
          },
          {
            name: 'languages',
            title: this.$t('upsale.languages'),
            value: this.stringJoiner(
              'languages',
              this.campaign.targets?.languageTarget.map((lang) => lang.name)
            )
          },
          {
            name: 'devices',
            title: this.$t('upsale.devices'),
            value: this.stringJoiner(
              'devices',
              [this.campaign.targets?.trafficTypeTarget.name]
            )
          },
          {
            title: this.$t('upsale.uniques'),
            value: { items: !!this.campaign.targets.uniquesTarget.accept }
          }
        ].filter((item) => item.value?.items)
      }
    },
    created() {
      this.fetchImage()
    },
    methods: {
      async fetchImage() {
        try {
          const adFormatIcon = await axios(this.campaign.adFormat.icon)
          this.adFormatImage = adFormatIcon.data
        } catch {
          this.adFormatImage = ''
        }
      },
      stringJoiner(targetName, items) {
        this.campaignTargetLabels[targetName] = items.join(', ')
        return {
          items: items[0],
          count: items.length > 1 ? ` +${items.length - 1}` : ''
        }
      },
      onCreateBtnClick() {
        gtmPush({
          event: GTM_EVENTS.CREATE_NEW_FORMAT,
          event_category: GTM_EVENTS_CATEGORY.FEATURE
        })
        this.$router.push({
          name: routeNames.OFFER_CAMPAIGN,
          params: {
            offerCampaign: this.campaign.getCampaignFormParams
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .box-shadow {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.07) !important;
  }
  .separator-item {
    border-top: 1px solid var(--v-secondary-base);
    padding-top: 16px;
  }
  .width-289 {
    min-width: 289px;
    max-width: 346px;
  }
  .height-70 {
    height: 70px;
  }
  .create-btn {
    margin-top: auto !important;
  }
  .height-200 {
    height: 162px;
  }
</style>
