<template>
  <div class="d-flex justify-center">
    <component :is="bannerSizeComponent" :locale="locale" :colors="colors" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'UpsaleBanner',
    computed: {
      ...mapState('settings', ['locale']),
      colors() {
        return this.$vuetify.theme.themes.light
      },
      bannerSizeComponent() {
        const currentSize = this.$vuetify.breakpoint.name
        return {
          xs: () => import('@/views/Upsale/UpsaleBanner/banners/BannerMobile.vue'),
          sm: () => import('@/views/Upsale/UpsaleBanner/banners/BannerTablet.vue'),
          md: () => import('@/views/Upsale/UpsaleBanner/banners/BannerDesktop.vue'),
          lg: () => import('@/views/Upsale/UpsaleBanner/banners/BannerLarge.vue'),
          xl: () => import('@/views/Upsale/UpsaleBanner/banners/BannerLarge.vue')
        }[currentSize]
      }
    }
  }
</script>
