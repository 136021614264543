<template>
  <div class="mx-1">
    <div
      class="
        border-info
        rounded
        py-6
        px-10
        mb-5
        mb-sm-8
        mb-md-10
        mb-lg-11
        d-flex
        justify-center
        align-center
        text-body-2
        text-md-body-1
        text-lg-h6
        font-weight-medium
      "
    >
      <v-icon color="info" class="mr-3">
        $check
      </v-icon>
      <div>
        {{ $t('upsale.your_campaign', { adFormat: createdCampaign.adFormat.name?.toLowerCase() }) }}
        <span class="info--text">
          {{ $t('upsale.successfully_created') }}
        </span>
      </div>
    </div>
    <upsale-banner class="mb-7 mb-sm-6 mb-md-10 mb-lg-11" />
    <upsale-offers :campaign-id="campaignId" class="mx-n4 mx-sm-0 overflow-hidden" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import moment from 'moment'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import guardSections from '@/types/guard-sections.js'
  import routeNames from '@/types/route-names.js'
  import { campaignsRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'
  import Campaign from '@/services/classes/Campaign.js'
  import UpsaleOffers from '@/views/Upsale/UpsaleOffers.vue'
  import UpsaleBanner from './UpsaleBanner/Index.vue'
  import googleAnalytics from '@/plugins/google-analytics.js'

  const { getGoogleAnalyticsSessionCount } = googleAnalytics

  export default {
    name: 'Upsale',
    components: { UpsaleOffers, UpsaleBanner },
    data() {
      return {
        createdCampaign: new Campaign(),
        UPSALE_PAGE_LIFETIME_HOURS: 2,
        googleSessionCount: 0
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork', 'serverTime', 'maxSessionCount']),
      campaignId() {
        return +this.$route.params.campaignId
      },
      measurementId() {
        return this.adNetwork.googleAnalyticsMeasurementId
      },
      hasUpsalesAccess() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.CAMPAIGN_OFFERS)
          && this.googleSessionCount < this.maxSessionCount
      },
      hasPageLifetime() {
        const serverTimeStamp = moment(this.serverTime).valueOf()
        const campaignTimeStamp = moment(this.createdCampaign?.createdAt).valueOf()

        return campaignTimeStamp + this.UPSALE_PAGE_LIFETIME_HOURS * 3600 * 1000 >= serverTimeStamp
      }
    },
    async created() {
      await this.fetchCampaign()
      await this.getGoogleSessionCount()

      if (!this.hasPageLifetime || !this.hasUpsalesAccess) {
        await this.$router.push({
          name: routeNames.CAMPAIGNS
        })
      } else {
        gtmPush({
          event: GTM_EVENTS.PAGE_VIEW_UPSALE,
          event_category: GTM_EVENTS_CATEGORY.PAGES,
          user_id: this.$auth.user?.id
        })
      }
    },
    methods: {
      async fetchCampaign() {
        try {
          const response = await campaignsRepository.show(this.campaignId)
          this.createdCampaign = new Campaign(response)
        } catch (error) {
          handleErrors(error)
          await this.$router.push({
            name: routeNames.CAMPAIGNS
          })
        }
      },
      async getGoogleSessionCount() {
        this.googleSessionCount = await getGoogleAnalyticsSessionCount(this.measurementId)
      }
    }
  }
</script>

<style scoped lang="scss">
  .border-info {
    border: 1px solid var(--v-info-base);
  }
</style>
