// eslint-disable-next-line prefer-rest-params
function gtag() { window.dataLayer.push(arguments) }

const getValueFromGtag = (name, measurementId) => {
  if (!measurementId) {
    return null
  }
  const valueFromGtag = new Promise((resolve) => {
    gtag('get', measurementId, name, resolve)
  })
  return valueFromGtag || null
}

const getGoogleAnalyticsClientId = async (measurementId) => {
  const clientId = await getValueFromGtag('client_id', measurementId)
  return clientId
}

const getGoogleAnalyticsSessionId = async (measurementId) => {
  const sessionId = await getValueFromGtag('session_id', measurementId)
  return sessionId
}

const getGoogleAnalyticsSessionCount = async (measurementId) => {
  const sessionCount = await getValueFromGtag('session_number', measurementId)
  return sessionCount
}

export default {
  getGoogleAnalyticsClientId,
  getGoogleAnalyticsSessionId,
  getGoogleAnalyticsSessionCount
}
