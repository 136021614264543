<template>
  <div class="fill-height global-relative white rounded">
    <div class="global-relative py-12 px-4">
      <v-chip v-if="isTablet" class="feature-plank text-uppercase font-weight-medium text-body-2 px-2" color="primary" label>
        {{ $t('upsale.new_features') }}
      </v-chip>
      <v-row class="flex-nowrap justify-space-between justify-md-center overflow-x-auto">
        <v-col v-for="index in totalLoaderCard" :key="index" class="flex-grow-0">
          <img src="@/assets/images/offer/card-offer-mock.webp" alt="Mock card" />
        </v-col>
      </v-row>
    </div>
    <v-overlay
      absolute
      opacity="0.95"
      color="white"
      class="d-flex align-start"
    >
      <div class="text-center pt-sm-10 mt-16 pt-2">
        <v-icon
          class="preloader-offer-icon mx-auto"
          size="24"
          color="info"
        >
          $update
        </v-icon>
        <div class="text-uppercase text-center font-weight-bold text-subtitle-2 black--text mt-3">
          {{ $t('upsale.loader_success') }}
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'UpsaleOffersLoader',
    data() {
      return {
        routeNames,
        totalLoaderCard: 3
      }
    },
    computed: {
      isTablet() {
        return this.$vuetify.breakpoint.smAndUp
      }
    }
  }
</script>

<style lang="scss" scoped>
  .preloader-offer-icon {
    animation: loading-spinner 1s linear infinite
  }
  .feature-plank {
    position: absolute;
    width: auto;
    top: 12px;
    left: 12px;
  }
  @keyframes loading-spinner {
    from{
      transform: rotate(0deg);
    }
    to{
      transform: rotate(-360deg);
    }
  }
</style>
